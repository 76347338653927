<template>
  <headBack :showBack="true">
    <template v-slot:title >
      <div class='title'>
        课程详情
      </div>
    </template>
  </headBack>
  <div class='header_cont'></div>
  <div class="detail-box">
    <img :src="item" alt="" v-for="(item,index) in info_url">

  </div>

  <div class="btn-box" @click="toPay">
      <div>立即报名咨询</div>
  </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import {reactive,ref,onMounted} from 'vue';
import homeApi from '@axios/home'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: "courseDetail",
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()


    const detail = ref('')
    const info_url = ref([])
    const getDetail = () => {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityProductInfo",
        productId: route.query.productId,
        userId: store.state.uid,
        newversion: '2120'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res) => {

        console.log(res);
        detail.value = res.data;
        info_url.value = res.data.INFO_URL.split(',');
        // list.value = res.data;

      })
    }
    getDetail()
    const toPay = () => {
      router.push({
        name:'communityOrder',
        query:{
          productId:route.query.productId,
          type:detail.value.TYPE
        }
      })

    }
    return {
      detail,
      info_url,
      toPay
    }
  }


}
</script>

<style scoped lang="scss">
.detail-box{
  width: 100%;
  font-size: 0;
  img{
    width: 100%;
  }
  padding-bottom: 100px;
}
.btn-box{
  position: fixed;
  width: 100%;
  left: 0;bottom:0;
  height:100px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    width:70%;
    color: white;
    background: rgb(0, 194, 145);
    border-color: rgb(0, 194, 145);
    text-align: center;
    border-radius: 40px;
    line-height: 70px;
    font-size: 34px;
  }

}
</style>
