import { render } from "./courseDetail.vue?vue&type=template&id=41ca5512&scoped=true"
import script from "./courseDetail.vue?vue&type=script&lang=js"
export * from "./courseDetail.vue?vue&type=script&lang=js"

import "./courseDetail.vue?vue&type=style&index=0&id=41ca5512&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-41ca5512"
/* hot reload */
if (module.hot) {
  script.__hmrId = "41ca5512"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('41ca5512', script)) {
    api.reload('41ca5512', script)
  }
  
  module.hot.accept("./courseDetail.vue?vue&type=template&id=41ca5512&scoped=true", () => {
    api.rerender('41ca5512', render)
  })

}

script.__file = "src/views/static/about/course/courseDetail.vue"

export default script